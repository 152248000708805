import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { CircularProgress, Box, IconButton } from "@mui/material";

import { ReactComponent as CardIcon } from "../../../../../assets/icons/icon-rolPayer-initialState.svg";
import { ReactComponent as CardIconDisabled } from "../../../../../assets/icons/icon-rolPayer-disableState.svg";
import { ReactComponent as CardIconHover } from "../../../../../assets/icons/icon-rolPayer-hoverState.svg";
import { ReactComponent as CardSelectedIcon } from "../../../../../assets/icons/icon-rolPayer-selectedState.svg";
import { ReactComponent as Close } from "../../../../../assets/icons/icon-close-gray-light.svg";
import { ReactComponent as IconApprover } from "../../../../../assets/icons/icon-rolApprover-initialState.svg";
import { ReactComponent as IconApproverDisabled } from "../../../../../assets/icons/icon-rolApprover-disabledState.svg";
import { ReactComponent as IconApproverHover } from "../../../../../assets/icons/icon-rolApprover-hoverState.svg";
import { ReactComponent as IconApproverSelected } from "../../../../../assets/icons/icon-rolApprover-selectedState.svg";
import { ReactComponent as PendIcon } from "../../../../../assets/icons/icon-rolSigner-initialState.svg";
import { ReactComponent as PendIconDisabled } from "../../../../../assets/icons/icon-rolSigner-disabledState.svg";
import { ReactComponent as PenIconHover } from "../../../../../assets/icons/icon-rolSigner-hoverState.svg";
import { ReactComponent as PenSelectedIcon } from "../../../../../assets/icons/icon-rolSigner-selectedState.svg";
import { ReactComponent as SigPayHover } from "../../../../../assets/icons/icon-rolPayerSigner-hoverState.svg";
import { ReactComponent as SigPayIcon } from "../../../../../assets/icons/icon-rolPayerSigner-initialState.svg";
import { ReactComponent as SigPayIconDisabled } from "../../../../../assets/icons/icon-rolPayerSigner-disabledState.svg";
import { ReactComponent as SigPaySeletedIcon } from "../../../../../assets/icons/icon-rolPayerSigner-selectedState.svg";
import {
    checkRut,
    formaRut,
    validatePhoneNumber,
    validation,
} from "../../../../../utils/validations";
import { saveTramitANFInfo } from "../../../../../store/formANFTramit";
import { randomRgba, verifiVariable } from "../../../../../utils/enums";
import { SignAuthorizationStyles } from "../../index.css";
import { theme } from "../../../../../theme/theme";
import ScreenSizeHook from "../../../../../hooks/ScreenSizeHook";
import { checkRutBusiness } from "../../../../../utils/api";
import InfoMessage from "../../../contentWelcome/molecules/infoMessage";
//import { mailValidate } from "../../../../common/services";

const CustomAddButton = styled(Button)((props) => ({
    fontStyle: "normal",
    boxShadow: "none",
    fontWeight: "bolder",
    textTransform: "none",
    fontSize: "0.813rem",
    padding: "16px 28px",
    border: "1px solid",
    borderRadius: "7px",
    lineHeight: 1.5,
    backgroundColor: props.theme.palette.primary.light,
    borderColor: props.theme.palette.primary.light,
    alignSelf: "center",
    fontFamily: ['"PoppinsBold"'].join(","),
    "&:hover": {
        backgroundColor: props.theme.palette.primary.xlight,
        borderColor: props.theme.palette.primary.xlight,
        boxShadow: "none",
    },
    "&:active": {
        boxShadow: "none",
        backgroundColor: "#0062cc",
        borderColor: "#005cbf",
    },
    "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
}));

const AddParticipants = ({ openAddDiag }) => {
    const {
        formANFTramit: { participants, editSigner },
        session: {
            login: { user },
        },
    } = useSelector((state) => state);
    const screenSizeHook = ScreenSizeHook();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [errorCheckRut, setErrorCheckRut] = useState(null);
    const styles = SignAuthorizationStyles();
    const [cardIcon, setCardIcon] = useState(<CardIcon style={{ width: '3.5rem' }} />);
    const [penIcon, setPenIcon] = useState(<PendIcon style={{ width: '3.5rem' }} />);
    const [cardPenIcon, setCardPenIcon] = useState(<SigPayIcon style={{ width: '3.5rem' }} />);
    const [approverIcon, setApproverIcon] = useState(<IconApprover style={{ width: '3.5rem' }} />);
    const [isNameBusiness, setIsNameBusiness] = useState(false);
    const [validEmail, setValidEmail] = useState(false);
    const [disabledCheckSigner, setDisabledCheckSigner] = useState(false);
    const [isPayDisabled, setIsPayDisabled] = useState(false);
    const [isApproverDisabled, setIsApproverDisabled] = useState(false);
    //const [validFormData, setValidFormData] = useState(false);
    const [addParticipantLoader, setAddParticipantLoader] = useState(false);
    const {
        watch,
        control,
        register,
        handleSubmit,
        reset,
        formState: { errors },
        setValue,
        clearErrors,
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        defaultValues: {
            email: "",
            rol: ["Firmante"].toString(),
            rut: "",
            fullName: "",
            phone: "",
            pay: "0",
            signerOrder: 1,
            signerLegal: "false",
            rutBusiness: "",
            nameBusiness: ""
        },
    });
    const [open, setOpen] = useState(false);

    const watchRol = watch("rol");

    const watchNameBusiness = watch("nameBusiness");

    const watchRutBusiness = watch("rutBusiness");

    const watchSignerLegal = watch("signerLegal");

    const watchForm = watch();

    const validateNameBusiness = () => {
        if (editSigner && editSigner.nameBusiness) {
            return true
        } else if (isNameBusiness) {
            return true
        } else {
            return 'Debes buscar la empresa'
        }
    };

    const checkRutReqst = async () => {
        try {
            const { token } = user;
            if (watchSignerLegal === "true") {
                setErrorCheckRut(null);
                setLoading(true);
                const {
                    data: { razonSocial, error },
                } = await checkRutBusiness(token, {
                    rut: watchRutBusiness,
                });
                if (!verifiVariable(razonSocial)) {
                    setValue("nameBusiness", razonSocial);
                    setIsNameBusiness(true)
                }

                if (!verifiVariable(error)) setErrorCheckRut(error["message"]);

                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.log("error: ", error);
        }
    };

    const handleClose = (event, reason) => {
        setValue("rol", "");
        setValue("pay", "0");
        clearErrors();
        setValidEmail(false);

        if (!verifiVariable(editSigner)) {

            reset({
                email: "",
                rol: ["Firmante"].toString(),
                rut: "",
                fullName: "",
                phone: "",
                pay: "0",
                signerOrder: 1,
                signerLegal: "false",
                rutBusiness: "",
                nameBusiness: ""
            });

            const formInfo = { ...watchForm };

            delete formInfo.rol;
            delete formInfo.pay;

            dispatch(
                saveTramitANFInfo({
                    indexe: "formParticipants",
                    value: formInfo,
                })
            );
        }
        dispatch(
            saveTramitANFInfo({
                indexe: "editSigner",
                value: undefined,
            })
        );

        if (!loading) {
            setOpen(false);
        }
    };

    const onsubmit = async (data) => {
        setAddParticipantLoader(true);
        const formData = {
            ...data,
            rol: data["rol"].split(","),
            signerOrder: parseInt(data["signerOrder"]) || 1
        };

        const arrayData = structuredClone(participants);

        if (!verifiVariable(editSigner)) {
            for (let index = 0; index < arrayData.length; index++) {
                const element = arrayData[index];

                if (element["email"] === editSigner["email"]) {
                    arrayData[index] = Object.assign(
                        { ...element },
                        { ...formData }
                    );
                }
            }
        } else {
            arrayData.push(
                Object.assign({ textColor: randomRgba() }, { ...formData })
            );
        }

        dispatch(
            saveTramitANFInfo({ indexe: "participants", value: arrayData })
        );
        reset();
        setIsNameBusiness(false);
        handleClose();
        setAddParticipantLoader(false);
    };

    const blockRadioAprover = useCallback(() => {
        const signPart = !verifiVariable(editSigner)
            ? [...participants].filter(
                (item) =>
                    item["rol"].includes("Aprobador") &&
                    item["rut"] !== editSigner["rut"]
            ).length === 3
            : [...participants].filter((item) =>
                item["rol"].includes("Aprobador")
            ).length === 3;

        return setIsApproverDisabled(signPart);

    }, [participants, editSigner]);

    const blockRadio100Pay = useCallback(() => {
        let pay = 0;

        const signPart = !verifiVariable(editSigner)
            ? [...participants].filter(
                (item) =>
                    item["rol"].includes("Pagador") &&
                    item["rut"] !== editSigner["rut"]
            )
            : [...participants].filter((item) =>
                item["rol"].includes("Pagador")
            );

        signPart.forEach((element) => {
            pay += parseInt(element["pay"]);
        });

        return pay !== 0;
    }, [participants, editSigner]);

    const blockRadio50Pay = useCallback(() => {
        let pay = 0;

        const signPart = !verifiVariable(editSigner)
            ? [...participants].filter(
                (item) =>
                    item["rol"].includes("Pagador") &&
                    item["rut"] !== editSigner["rut"]
            )
            : [...participants].filter((item) =>
                item["rol"].includes("Pagador")
            );

        signPart.forEach((element) => {
            pay += parseInt(element["pay"]);
        });

        return pay > 50;
    }, [participants, editSigner]);

    const disabledPayCheck = useCallback(() => {
        const payInfo = [...participants]
            .filter((participant) => {
                return verifiVariable(editSigner)
                    ? participant
                    : participant["rut"] !== editSigner["rut"];
            })
            .map((participant) => {
                return participant["pay"];
            })
            .filter((participant) => {
                return ["50", "100"].includes(participant);
            });

        return payInfo.includes("100") ||
            (payInfo.length === 2 && payInfo.includes("50"))
            ? setIsPayDisabled(true)
            : setIsPayDisabled(false);
    }, [participants, editSigner]);

    const disableSignerParticipants = () => {
        if (!verifiVariable(participants)) {
            const participantList = [...participants]
                .filter(participant => participant.rut !== editSigner?.rut)
                .filter((participant) => participant["rol"].includes("Firmante"));

            participantList.length >= 10 ? setDisabledCheckSigner(true) : setDisabledCheckSigner(false);
        }
    };

    useEffect(() => {
        if (openAddDiag > 0) setOpen(true);
        disabledPayCheck();
        disableSignerParticipants();
        blockRadioAprover();
        // eslint-disable-next-line
    }, [openAddDiag]);

    useEffect(() => {
        if (editSigner) {
            setValue("fullName", editSigner?.["fullName"]);
            setValue("phone", editSigner?.["phone"]);
            setValue("email", editSigner?.["email"]);
            setValue("pay", editSigner?.["pay"]);
            setValue("rol", editSigner?.["rol"].toString());
            setValue("signerOrder", editSigner?.["signerOrder"]);
            setValue("signerLegal", editSigner?.["signerLegal"]);
            setValue("rutBusiness", editSigner?.["rutBusiness"]);
            setValue("nameBusiness", editSigner?.["nameBusiness"]);
            setValue("rut", editSigner?.["rut"]);
        };
        // eslint-disable-next-line
    }, [editSigner]);

    useEffect(() => {
        if (watchRol === "Pagador") {
            setValue("signerLegal", "false");
            setValue("nameBusiness", "");
            setValue("rutBusiness", "");
        }
        // eslint-disable-next-line
    }, [watchRol]);

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            fullWidth={true}
            maxWidth={"sm"}
        >
            <form
                onSubmit={handleSubmit(onsubmit)}
                className={styles.contentFomAddpart}
            >

                <Box className={styles.modalHeader}>
                    <Typography
                        variant="h6"
                        gutterBottom
                        className={styles.titleAddDiag}
                    >
                        {editSigner
                            ? `Editando participante ${participants.findIndex(ele => ele.email === editSigner.email) + 1}`
                            : `Participante ${participants.length + 1}`}
                    </Typography>

                    <IconButton
                        size="medium"
                        className="closeIcon"
                        onClick={handleClose}
                    >
                        <Close />
                    </IconButton>

                </Box>

                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={
                            !verifiVariable(screenSizeHook) &&
                                screenSizeHook === "xs"
                                ? 12
                                : 6
                        }
                    >
                        <Grid item xs={12} sx={{ marginBottom: '.5rem' }}>
                            <Controller
                                name={`fullName`}
                                control={control}
                                rules={{
                                    required: "Este campo es requerido",
                                    maxLength: {
                                        value: 50,
                                        message: 'La longitud máxima es de 50 caracteres'
                                    },
                                    pattern: {
                                        value: validation.nombre,
                                        message: 'No está permitido utilizar ciertos caracteres especiales. Carcateres especiales permitidos "-_#()@:?$+= áéíóúÁÉÍÓÚñÑüÜ.,!"'
                                    }
                                }}
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                    fieldState,
                                }) => {
                                    return (
                                        <FormControl
                                            fullWidth
                                            error={!!fieldState.error}
                                        >
                                            <label
                                                htmlFor="fullName"
                                                className={styles.titleFormAddDiag}
                                            >
                                                <Typography variant="h6">
                                                    Nombre completo
                                                </Typography>
                                            </label>

                                            <TextField
                                                id="fullName"
                                                autoComplete="name"
                                                type="text"
                                                error={!!fieldState.error}
                                                ref={ref}
                                                onBlur={onBlur}
                                                className={styles.emailInput}
                                                onChange={onChange}
                                                value={value}
                                                placeholder={"Nombre completo"}
                                                color={
                                                    fieldState.error
                                                        ? "error"
                                                        : "success"
                                                }
                                                focused={
                                                    fieldState.isTouched ||
                                                    fieldState.isDirty
                                                }
                                                fullWidth
                                            />

                                            <FormHelperText
                                                className={styles.errorText}
                                                hidden={
                                                    fieldState.error
                                                        ? false
                                                        : true
                                                }
                                            >
                                                {fieldState.error
                                                    ? fieldState.error.message
                                                    : null}
                                            </FormHelperText>

                                            <br />
                                        </FormControl>
                                    );
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Controller
                                name={`rut`}
                                control={control}
                                rules={{
                                    required: "Este campo es requerido",
                                    validate: {
                                        isPassPortOrRut: (value) => {
                                            if (
                                                [checkRut(value)].includes(true)
                                            ) {
                                                return true;
                                            } else {
                                                return "RUT inválido";
                                            }
                                        },
                                        uniqueRut: (value) => {
                                            const list = [
                                                ...participants.filter(
                                                    (item) =>
                                                        ![
                                                            null,
                                                            undefined,
                                                            "",
                                                        ].includes(item["rut"])
                                                ),
                                            ];

                                            const rutList = [];

                                            for (
                                                let index = 0;
                                                index < list.length;
                                                index++
                                            ) {
                                                const element = list[index];

                                                if (editSigner) {
                                                    // Verifica si va a editar
                                                    if (
                                                        editSigner["rut"] !==
                                                        element["rut"]
                                                    )
                                                        rutList.push(
                                                            element["rut"]
                                                        );
                                                } else {
                                                    rutList.push(
                                                        element["rut"]
                                                    );
                                                }
                                            }

                                            return rutList.includes(value)
                                                ? "RUT duplicado"
                                                : true;
                                        },
                                    },
                                }}
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                    fieldState,
                                }) => {
                                    return (
                                        <FormControl
                                            fullWidth
                                            error={!!fieldState.error}
                                        >
                                            <label
                                                htmlFor="rut"
                                                className={styles.titleFormAddDiag}
                                            >
                                                <Typography variant="h6">
                                                    RUT
                                                </Typography>
                                            </label>

                                            <TextField
                                                id="rut"
                                                autoComplete="on"
                                                type="text"
                                                error={!!fieldState.error}
                                                ref={ref}
                                                onBlur={onBlur}
                                                className={styles.emailInput}
                                                onChange={(e) => {
                                                    onChange(e);
                                                    const value = e.target.value;
                                                    const formattedRut = formaRut(value);
                                                    setValue('rut', formattedRut);
                                                }}
                                                value={value}
                                                placeholder={
                                                    "Ejemplo: 11111111-1"
                                                }
                                                color={
                                                    fieldState.error
                                                        ? "error"
                                                        : "success"
                                                }
                                                focused={
                                                    fieldState.isTouched ||
                                                    fieldState.isDirty
                                                }
                                                fullWidth
                                            />

                                            <FormHelperText
                                                className={styles.errorText}
                                                hidden={
                                                    fieldState.error
                                                        ? false
                                                        : true
                                                }
                                            >
                                                {fieldState.error
                                                    ? fieldState.error.message
                                                    : null}
                                            </FormHelperText>

                                            <br />
                                        </FormControl>
                                    );
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Controller
                                name={`email`}
                                control={control}
                                rules={{
                                    required: "Este campo es requerido",
                                    pattern: {
                                        message: "Formato de correo inválido",
                                        value: validation.email,
                                    },
                                    validate: {
                                        uniqueEmail: (value) => {
                                            const list = [...participants.filter((item) => ![null, undefined, "",].includes(item["email"]))];

                                            const emailList = [];

                                            for (let index = 0; index < list.length; index++) {
                                                const element = list[index];

                                                if (editSigner) {
                                                    // verifica si va a aeditar
                                                    if (editSigner["email"] !== element["email"]) emailList.push(element["email"]);
                                                } else {
                                                    emailList.push(element["email"]);
                                                }
                                            }

                                            return emailList.includes(value) ? "Email duplicado" : true;
                                        },
                                    },
                                }}
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                    fieldState,
                                }) => {
                                    return (
                                        <FormControl
                                            fullWidth
                                            error={!!fieldState.error}
                                        >
                                            <label
                                                htmlFor="email"
                                                className={styles.titleFormAddDiag}
                                            >
                                                <Typography variant="h6">
                                                    Correo electrónico
                                                </Typography>
                                            </label>

                                            <TextField
                                                id="email"
                                                autoComplete="email"
                                                type="text"
                                                error={!!fieldState.error}
                                                ref={ref}
                                                onBlur={onBlur}
                                                className={styles.emailInput}
                                                onChange={(e) => {
                                                    onChange(e);
                                                    setValidEmail(false);
                                                    const deleteSpaces = e.target.value.replace(/\s/g, '');
                                                    setValue('email', deleteSpaces);
                                                }}
                                                value={value}
                                                placeholder={"tucorreo@mail.com"}
                                                color={
                                                    fieldState.error ? "error" :
                                                        validEmail ? "info" :
                                                            "success"
                                                }
                                                focused={
                                                    fieldState.isTouched ||
                                                    fieldState.isDirty
                                                }
                                                fullWidth
                                            />

                                            {validEmail && (
                                                <InfoMessage
                                                    icon={'warning'}
                                                    text={"Nuestro sistema detectó un problema con este correo. Puedes modificarlo, o si estás seguro de que el correo es correcto y deseas continuar, haz clic en 'Agregar participante'."}
                                                />
                                            )}

                                            <FormHelperText
                                                className={styles.errorText}
                                                hidden={
                                                    fieldState.error
                                                        ? false
                                                        : true
                                                }
                                            >
                                                {fieldState.error
                                                    ? fieldState.error.message
                                                    : null}
                                            </FormHelperText>

                                            <br />
                                        </FormControl>
                                    );
                                }}
                            />
                        </Grid>

                        {
                            !["Aprobador", "Pagador", "Firmante"].includes(watchRol) &&
                            (
                                <Grid item xs={12}>
                                    <Controller
                                        name={`phone`}
                                        control={control}
                                        rules={{
                                            required: "Este campo es requerido",
                                            validate: {
                                                validPhoneNumber: (value) => validatePhoneNumber(value) || "Formato de numero telefonico invalido",
                                                validateBlanks: (value) => !/\s/g.test(value) || "No estan permitidos los espacios en blanco",
                                                uniquePhone: (value) => {
                                                    const list = [...participants.filter((item) => ![null, undefined, "",].includes(item["email"]))];

                                                    const phonelList = [];

                                                    for (let index = 0; index < list.length; index++) {
                                                        const element = list[index];

                                                        if (editSigner) {
                                                            // verifica si va a aeditar
                                                            if (editSigner["phone"] !== element["phone"]) phonelList.push(element["phone"]);
                                                        } else {
                                                            phonelList.push(element["phone"]);
                                                        }
                                                    }

                                                    return phonelList.includes(value) ? "Nro de teléfono duplicado" : true;
                                                }
                                            }
                                        }}
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                            fieldState,
                                        }) => {
                                            return (
                                                <FormControl
                                                    fullWidth
                                                    error={!!fieldState.error}
                                                >
                                                    <label
                                                        htmlFor="phone"
                                                        className={styles.titleFormAddDiag}
                                                    >
                                                        <Typography variant="h6">
                                                            Número de teléfono (WhatsApp)
                                                        </Typography>
                                                    </label>

                                                    <TextField
                                                        id="phone"
                                                        autoComplete="tel"
                                                        type="text"
                                                        error={!!fieldState.error}
                                                        ref={ref}
                                                        onBlur={onBlur}
                                                        className={styles.emailInput}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                            const deleteSpaces = e.target.value.replace(/[^\d+]/g, '');
                                                            setValue('phone', deleteSpaces);
                                                        }}
                                                        value={value}
                                                        placeholder={"+56000000000"}
                                                        color={fieldState.error ? "error" : "success"}
                                                        focused={fieldState.isTouched || fieldState.isDirty}
                                                        fullWidth
                                                    />

                                                    <FormHelperText
                                                        className={styles.errorText}
                                                        hidden={
                                                            fieldState.error
                                                                ? false
                                                                : true
                                                        }
                                                    >
                                                        {fieldState.error
                                                            ? fieldState.error.message
                                                            : null}
                                                    </FormHelperText>

                                                    <br />
                                                </FormControl>
                                            );
                                        }}
                                    />
                                </Grid>
                            )
                        }

                    </Grid>

                    <Grid
                        item
                        xs={
                            !verifiVariable(screenSizeHook) &&
                                screenSizeHook === "xs"
                                ? 12
                                : 6
                        }
                    >

                        {
                            ["Aprobador", "Pagador", "Firmante"].includes(watchRol) &&
                            (
                                <Grid item xs={12}>
                                    <Controller
                                        name={`phone`}
                                        control={control}
                                        rules={{
                                            required: "Este campo es requerido",
                                            validate: {
                                                validPhoneNumber: (value) => validatePhoneNumber(value) || "Formato de numero telefonico invalido",
                                                validateBlanks: (value) => !/\s/g.test(value) || "No estan permitidos los espacios en blanco",
                                                uniquePhone: (value) => {
                                                    const list = [...participants.filter((item) => ![null, undefined, "",].includes(item["phone"]))];

                                                    const phonelList = [];

                                                    for (let index = 0; index < list.length; index++) {
                                                        const element = list[index];

                                                        if (editSigner) {
                                                            // verifica si va a aeditar
                                                            if (editSigner["phone"] !== element["phone"]) phonelList.push(element["phone"]);
                                                        } else {
                                                            phonelList.push(element["phone"]);
                                                        }
                                                    }

                                                    return phonelList.includes(value) ? "Nro de teléfono duplicado" : true;
                                                }
                                            },
                                        }}
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                            fieldState,
                                        }) => {
                                            return (
                                                <FormControl
                                                    fullWidth
                                                    error={!!fieldState.error}
                                                >
                                                    <label
                                                        htmlFor="phone"
                                                        className={styles.titleFormAddDiag}
                                                    >
                                                        <Typography variant="h6">
                                                            Número de teléfono (WhatsApp)
                                                        </Typography>
                                                    </label>

                                                    <TextField
                                                        id="phone"
                                                        autoComplete="tel"
                                                        type="text"
                                                        error={!!fieldState.error}
                                                        ref={ref}
                                                        onBlur={onBlur}
                                                        className={styles.emailInput}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                            const deleteSpaces = e.target.value.replace(/[^\d+]/g, '');
                                                            setValue('phone', deleteSpaces);
                                                        }}
                                                        value={value}
                                                        placeholder={"+56000000000"}
                                                        color={fieldState.error ? "error" : "success"}
                                                        focused={fieldState.isTouched || fieldState.isDirty}
                                                        fullWidth
                                                    />

                                                    <FormHelperText
                                                        className={styles.errorText}
                                                        hidden={
                                                            fieldState.error
                                                                ? false
                                                                : true
                                                        }
                                                    >
                                                        {fieldState.error
                                                            ? fieldState.error.message
                                                            : null}
                                                    </FormHelperText>

                                                    <br />
                                                </FormControl>
                                            );
                                        }}
                                    />
                                </Grid>
                            )
                        }

                        <Grid item xs={12}>
                            <FormControl
                                sx={{
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    flexWrap: "wrap",
                                    marginBottom: '1.2rem'
                                }}
                                fullWidth
                                error={errors.rol ? true : false}
                            >
                                <label className={styles.titleFormAddDiag}>
                                    <Typography variant="h6">
                                        ¿Qué rol desempeñará el participante?
                                    </Typography>
                                </label>

                                <RadioGroup
                                    defaultValue={
                                        disabledCheckSigner
                                            ? ''
                                            : editSigner
                                                ? editSigner["rol"].toString()
                                                : "Firmante"
                                    }
                                    row
                                    className={styles.buttomsRol}
                                >
                                    <Grid className={styles.rolIcon}>
                                        <Tooltip
                                            title={
                                                <Typography
                                                    variant="subtitle1"
                                                    color={["white.main"]}
                                                >
                                                    Esta persona participará pagando
                                                    el trámite.
                                                </Typography>
                                            }
                                            placement="top"
                                            TransitionComponent={Fade}
                                            TransitionProps={{
                                                timeout: 600,
                                            }}
                                            arrow
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        onMouseEnter={() => setCardIcon(<CardIconHover style={{ width: '3.5rem' }} />)}
                                                        onMouseLeave={() => setCardIcon(<CardIcon style={{ width: '3.5rem' }} />)}
                                                        disabled={isPayDisabled}
                                                        icon={
                                                            isPayDisabled
                                                                ? <CardIconDisabled style={{ width: '3.5rem' }} />
                                                                : cardIcon
                                                        }
                                                        checkedIcon={<CardSelectedIcon style={{ width: '3.5rem' }} />}
                                                        {...register("rol", {
                                                            required:
                                                                "Debe seleccionar al menos un rol",
                                                        })}
                                                        value={"Pagador"}
                                                    />
                                                }
                                                labelPlacement="bottom"
                                            />
                                        </Tooltip>
                                    </Grid>

                                    <Grid className={styles.rolIcon}>
                                        <Tooltip
                                            title={
                                                !disabledCheckSigner ? (
                                                    <Typography
                                                        variant="subtitle1"
                                                        color={["white.main"]}
                                                    >
                                                        Esta persona participará
                                                        únicamente como firmante.
                                                    </Typography>
                                                ) : (
                                                    <Typography
                                                        variant="subtitle1"
                                                        color={["white.main"]}
                                                    >
                                                        Solo esta permitido un maximo de 10 firmantes por trámites.
                                                    </Typography>
                                                )
                                            }
                                            placement="top"
                                            TransitionComponent={Fade}
                                            TransitionProps={{
                                                timeout: 600,
                                            }}
                                            arrow
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        disabled={disabledCheckSigner}
                                                        onMouseEnter={() => setPenIcon(<PenIconHover style={{ width: '3.5rem' }} />)}
                                                        onMouseLeave={() => setPenIcon(<PendIcon style={{ width: '3.5rem' }} />)}
                                                        icon={
                                                            disabledCheckSigner
                                                                ? <PendIconDisabled style={{ width: '3.5rem' }} />
                                                                : penIcon
                                                        }
                                                        checkedIcon={<PenSelectedIcon style={{ width: '3.5rem' }} />}
                                                        {...register("rol", {
                                                            required:
                                                                "Debe seleccionar al menos un rol",
                                                        })}
                                                        value={"Firmante"}
                                                        onClick={() =>
                                                            setValue("pay", "0")
                                                        }
                                                    />
                                                }
                                                labelPlacement="bottom"
                                            />
                                        </Tooltip>
                                    </Grid>

                                    <Grid className={styles.rolIcon}>
                                        <Tooltip
                                            title={
                                                !disabledCheckSigner ? (
                                                    <Typography
                                                        variant="subtitle1"
                                                        color={["white.main"]}
                                                    >
                                                        Esta persona participará
                                                        firmando y pagando el documento.
                                                    </Typography>
                                                ) : (
                                                    <Typography
                                                        variant="subtitle1"
                                                        color={["white.main"]}
                                                    >
                                                        Solo esta permitido un maximo de 10 firmantes por trámites.
                                                    </Typography>
                                                )
                                            }
                                            placement="top"
                                            TransitionComponent={Fade}
                                            TransitionProps={{
                                                timeout: 600,
                                            }}
                                            arrow
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        disabled={isPayDisabled || disabledCheckSigner}
                                                        onMouseEnter={() => setCardPenIcon(<SigPayHover style={{ width: '3.5rem' }} />)}
                                                        onMouseLeave={() => setCardPenIcon(<SigPayIcon style={{ width: '3.5rem' }} />)}
                                                        icon={
                                                            (isPayDisabled || disabledCheckSigner)
                                                                ? <SigPayIconDisabled style={{ width: '3.5rem' }} />
                                                                : cardPenIcon
                                                        }
                                                        checkedIcon={<SigPaySeletedIcon style={{ width: '3.5rem' }} />}
                                                        {...register("rol", {
                                                            required:
                                                                "Debe seleccionar al menos un rol",
                                                        })}
                                                        value={"Firmante,Pagador"}
                                                    />
                                                }
                                                labelPlacement="bottom"
                                            />
                                        </Tooltip>
                                    </Grid>

                                    <Grid className={styles.rolIcon}>
                                        <Tooltip
                                            title={
                                                <Typography
                                                    variant="subtitle1"
                                                    color={["white.main"]}
                                                >
                                                    Esta persona participará
                                                    aprobando el documento.
                                                </Typography>
                                            }
                                            placement="top"
                                            TransitionComponent={Fade}
                                            TransitionProps={{
                                                timeout: 600,
                                            }}
                                            arrow
                                        >

                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        disabled={isApproverDisabled}
                                                        icon={approverIcon}
                                                        onMouseEnter={() => setApproverIcon(<IconApproverHover style={{ width: '3.5rem' }} />)}
                                                        onMouseLeave={() => setApproverIcon(<IconApprover style={{ width: '3.5rem' }} />)}
                                                        checkedIcon={
                                                            isApproverDisabled
                                                                ? <IconApproverDisabled style={{ width: '3.5rem' }} />
                                                                : <IconApproverSelected style={{ width: '3.5rem' }} />
                                                        }
                                                        {...register("rol", {
                                                            required:
                                                                "Debe seleccionar al menos un rol",
                                                        })}
                                                        value={"Aprobador"}
                                                        onClick={() =>
                                                            setValue("pay", "0")
                                                        }
                                                    />
                                                }
                                                labelPlacement="bottom"
                                            />

                                        </Tooltip>
                                    </Grid>

                                </RadioGroup>

                                <FormHelperText
                                    className={styles.errorText}
                                    hidden={errors.rol ? false : true}
                                >
                                    {errors.rol ? errors.rol?.message : ""}
                                </FormHelperText>
                            </FormControl>
                        </Grid>

                        {!verifiVariable(watchRol) &&
                            [...watchRol.split(",")].includes("Pagador") && (
                                <Grid item xs={12}>
                                    <Controller
                                        name={`pay`}
                                        control={control}
                                        rules={{
                                            validate: {
                                                verifyPay: (value) => {
                                                    const rolValidate = [
                                                        ...watchRol.split(","),
                                                    ];

                                                    return (
                                                        (parseInt(value) !==
                                                            0 &&
                                                            rolValidate.includes(
                                                                "Pagador"
                                                            )) ||
                                                        "Seleccionar porcentaje de pago."
                                                    );
                                                },
                                            },
                                        }}
                                        render={({
                                            field: {
                                                onChange,
                                                onBlur,
                                                value,
                                                ref,
                                            },
                                            fieldState,
                                        }) => {
                                            return (
                                                <FormControl
                                                    fullWidth
                                                    error={
                                                        fieldState.error
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    <label className={styles.titleFormAddDiag}>
                                                        <Typography variant="h6">
                                                            ¿Este participante
                                                            realizará el pago del
                                                            50% o el 100% del
                                                            servicio?
                                                        </Typography>
                                                    </label>

                                                    <RadioGroup
                                                        defaultValue={
                                                            editSigner
                                                                ? editSigner[
                                                                "pay"
                                                                ]
                                                                : "0"
                                                        }
                                                        row
                                                    >
                                                        <FormControlLabel
                                                            disabled={blockRadio50Pay()}
                                                            control={
                                                                <Radio
                                                                    sx={{
                                                                        "&.Mui-checked":
                                                                        {
                                                                            color: `${theme.palette.primary.light} !important`,
                                                                        },
                                                                    }}
                                                                    value="50"
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                />
                                                            }
                                                            label="50%"
                                                        />

                                                        <FormControlLabel
                                                            disabled={blockRadio100Pay()}
                                                            control={
                                                                <Radio
                                                                    sx={{
                                                                        "&.Mui-checked":
                                                                        {
                                                                            color: `${theme.palette.primary.light} !important`,
                                                                        },
                                                                    }}
                                                                    value="100"
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                />
                                                            }
                                                            label="100%"
                                                        />
                                                    </RadioGroup>

                                                    <FormHelperText
                                                        hidden={
                                                            fieldState.error
                                                                ? false
                                                                : true
                                                        }
                                                    >
                                                        {fieldState.error
                                                            ? fieldState.error
                                                                .message
                                                            : null}
                                                    </FormHelperText>
                                                </FormControl>
                                            );
                                        }}
                                    />
                                </Grid>
                            )}

                        {watchRol && (watchRol === 'Firmante' || watchRol === 'Firmante,Pagador' || watchRol === 'Aprobador') && (
                            <Grid item xs={12}>
                                <Controller
                                    name={`signerOrder`}
                                    control={control}
                                    rules={{
                                        required: "Este campo es requerido",
                                        validate: {
                                            minValue: (value) => value >= 1 || "Debe ser 1 o mayor que 1",
                                        },
                                    }}
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                        fieldState,
                                    }) => {
                                        return (
                                            <FormControl
                                                fullWidth
                                                error={
                                                    fieldState.error ? true : false
                                                }
                                            >
                                                <label className={styles.titleFormAddDiag}>
                                                    <Typography variant="h6"                                                    >
                                                        Orden de firma del firmante
                                                    </Typography>
                                                </label>

                                                <TextField
                                                    type="number"
                                                    error={!!fieldState.error}
                                                    ref={ref}
                                                    onBlur={onBlur}
                                                    className={styles.emailInput}
                                                    onChange={onChange}
                                                    value={value}
                                                    placeholder={""}
                                                    color={
                                                        fieldState.error
                                                            ? "error"
                                                            : "success"
                                                    }
                                                    focused={
                                                        fieldState.isTouched ||
                                                        fieldState.isDirty
                                                    }
                                                    fullWidth
                                                />

                                                <FormHelperText
                                                    className={styles.errorText}
                                                    hidden={
                                                        fieldState.error
                                                            ? false
                                                            : true
                                                    }
                                                >
                                                    {fieldState.error
                                                        ? fieldState.error.message
                                                        : null}
                                                </FormHelperText>

                                                <br />
                                            </FormControl>
                                        );
                                    }}
                                />
                            </Grid>
                        )}

                        {/Firmante/gi.test(watchRol) && (
                            <Grid item xs={12}>
                                <Controller
                                    name={`signerLegal`}
                                    control={control}
                                    rules={{
                                        required: "Este campo es requerido",
                                    }}
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                        fieldState,
                                    }) => {
                                        return (
                                            <FormControl
                                                fullWidth
                                                error={!!fieldState.error}
                                            >
                                                <label className={styles.titleFormAddDiag}>
                                                    <Typography variant="h6">
                                                        ¿Es representante legal?
                                                    </Typography>
                                                </label>

                                                <RadioGroup
                                                    onChange={() => {
                                                        setValue(
                                                            "nameBusiness",
                                                            ""
                                                        );
                                                        setValue(
                                                            "rutBusiness",
                                                            ""
                                                        );
                                                    }}
                                                    sx={{
                                                        "& label": {
                                                            marginBottom: "0px",
                                                        },
                                                    }}
                                                    defaultValue={
                                                        !verifiVariable(
                                                            editSigner
                                                        )
                                                            ? editSigner[
                                                            "signerLegal"
                                                            ]
                                                            : "false"
                                                    }
                                                    row
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Radio
                                                                sx={{
                                                                    "&.Mui-checked":
                                                                    {
                                                                        color: `${theme.palette.primary.light} !important`,
                                                                    },
                                                                }}
                                                                value={"true"}
                                                                onChange={
                                                                    onChange
                                                                }
                                                            />
                                                        }
                                                        label="SI"
                                                    />

                                                    <FormControlLabel
                                                        control={
                                                            <Radio
                                                                sx={{
                                                                    "&.Mui-checked":
                                                                    {
                                                                        color: `${theme.palette.primary.light} !important`,
                                                                    },
                                                                }}
                                                                value={"false"}
                                                                onChange={
                                                                    onChange
                                                                }
                                                            />
                                                        }
                                                        label="NO"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        );
                                    }}
                                />
                            </Grid>
                        )}
                    </Grid>

                    {watchSignerLegal === "true" && (
                        <Controller
                            name={`rutBusiness`}
                            control={control}
                            rules={{
                                required: "Este campo es requerido",
                                validate: {
                                    value: validateNameBusiness
                                }
                            }}
                            render={({
                                field: { onChange, onBlur, value, ref },
                                fieldState,
                            }) => {
                                return (
                                    <FormControl
                                        error={!!fieldState.error}
                                        fullWidth
                                    >
                                        <label className={styles.titleFormAddDiag}>
                                            <Typography variant="h6"                                            >
                                                Indique el rut de la empresa a la
                                                que representa
                                            </Typography>
                                        </label>

                                        <Paper
                                            component="form"
                                            sx={{
                                                p: "2px 4px",
                                                display: "flex",
                                                alignItems: "center",
                                                width: "100%",
                                                marginLeft: "16px",
                                            }}
                                        >
                                            <InputBase
                                                disabled={
                                                    loading ||
                                                    !verifiVariable(
                                                        watchNameBusiness
                                                    )
                                                }
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                    const value = e.target.value;
                                                    const formattedRut = formaRut(value);
                                                    setValue('rutBusiness', formattedRut);
                                                }}
                                                sx={{ ml: 1, flex: 1 }}
                                                placeholder="Indique el rut de la empresa a la que representa"
                                                inputProps={{
                                                    "aria-label":
                                                        "Rut de empresa",
                                                }}
                                            />

                                            {loading && (
                                                <Box sx={{ display: "flex" }}>
                                                    <CircularProgress />
                                                </Box>
                                            )}

                                            {!loading &&
                                                verifiVariable(
                                                    watchNameBusiness
                                                ) && (
                                                    <IconButton
                                                        disabled={
                                                            verifiVariable(
                                                                value
                                                            ) ||
                                                            ![
                                                                checkRut(value),
                                                            ].includes(true)
                                                        }
                                                        type="button"
                                                        sx={{ p: "10px" }}
                                                        aria-label="search"
                                                        onClick={checkRutReqst}
                                                    >
                                                        <SearchIcon />
                                                    </IconButton>
                                                )}

                                            {!verifiVariable(
                                                watchNameBusiness
                                            ) && (
                                                    <IconButton
                                                        disabled={
                                                            verifiVariable(value) ||
                                                            ![
                                                                checkRut(value),
                                                            ].includes(true)
                                                        }
                                                        type="button"
                                                        sx={{ p: "10px" }}
                                                        aria-label="search"
                                                        onClick={() => {
                                                            setValue(
                                                                "nameBusiness",
                                                                ""
                                                            );
                                                            setValue(
                                                                "rutBusiness",
                                                                ""
                                                            );
                                                            setIsNameBusiness(false);
                                                        }}
                                                    >
                                                        <HighlightOffIcon />
                                                    </IconButton>
                                                )}
                                        </Paper>

                                        <FormHelperText
                                            className={styles.errorText}
                                            hidden={fieldState.error ? false : true}
                                        >
                                            {fieldState.error
                                                ? fieldState.error.message
                                                : null}
                                        </FormHelperText>
                                    </FormControl>
                                );
                            }}
                        />
                    )}

                    {!verifiVariable(errorCheckRut) && (
                        <Typography
                            sx={{
                                marginBottom: "0px",
                                marginLeft: "16px",
                                marginTop: "7px",
                            }}
                            padding={"0px"}
                            variant="h6"
                            gutterBottom
                        >
                            {errorCheckRut}
                        </Typography>
                    )}

                    {!verifiVariable(watchNameBusiness) && (
                        <Typography
                            sx={{
                                marginBottom: "0px",
                                marginLeft: "16px",
                                marginTop: "7px",
                            }}
                            padding={"0px"}
                            variant="h6"
                            gutterBottom
                        >
                            {watchNameBusiness}
                        </Typography>
                    )}

                    <Grid item xs={12}>
                        <CustomAddButton
                            fullWidth
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            {addParticipantLoader
                                ? <CircularProgress size={20} color="white" />
                                : 'Agregar participante'}
                        </CustomAddButton>
                    </Grid>

                </Grid>

            </form>

        </Dialog >
    );
};

export default AddParticipants;