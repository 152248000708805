import { Fragment, useEffect, useState } from "react";
import { FaUserAlt } from "react-icons/fa";
import { BsEye, BsTrash } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import PropTypes from "prop-types";
import { GeneralListProcedStyles } from "../../index.css";
import RenderStatus from "../../atoms/renderStatus";
import { to, verifiVariable } from "../../../../../utils/enums";

const Rows = ({ row, deleteContrat }) => {
    const navigate = useNavigate();
    const styles = GeneralListProcedStyles();
    const [open, setOpen] = useState(false);
    const [urlDetail, setUrlDetail] = useState("");

    useEffect(() => {
        if (row["sProcess"] === "EXPRESS")
            setUrlDetail(to.DETAIL_EXPRESS_PROCEDURE);
        if (row["sProcess"] === "NORMAL") setUrlDetail(to.CONTRACT_DETAIL);
        if (row["sProcess"] === "ANF") setUrlDetail(to.ANF_DETAIL);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [row["sProcess"]]);

    return (
        <Fragment>
            <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() =>
                            navigate(urlDetail, {
                                state:
                                    row["sProcess"] === "NORMAL"
                                        ? row["sContractID"]
                                        : { sContractID: row["sContractID"] },
                            })
                        }
                    >
                        <BsEye className="icon--xs icon-pointer icon--blue-light" />
                    </IconButton>

                    {
                        ["CREATED", "REVIEW"].includes(row.sStatus["sStatus"]) &&
                        ["EXPRESS"].includes(row["sProcess"])
                        && (
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() =>
                                    deleteContrat({
                                        sContractID: row["sContractID"],
                                        sProcess: row["sProcess"],
                                    })
                                }
                            >
                                <BsTrash className="icon--xxs icon--red icon-pointer" />
                            </IconButton>
                        )
                    }
                </TableCell>

                <TableCell className={styles.rowText}>{row.autoId}</TableCell>

                <TableCell className={styles.rowText}>
                    {row.tramit["sTipoContrato"]} <br />
                    <Typography variant="caption">
                        {row.tramit["sTipoFirma"]}
                    </Typography>
                </TableCell>

                <TableCell className={styles.rowText}>{!verifiVariable(row.sContractName) ? (row.sContractName.length >= 15 ? row.sContractName.substring(0, 15) + "..." : row.sContractName) : 'No especificado'}</TableCell>

                <TableCell
                    component="th"
                    scope="row"
                    className={styles.rowText}
                >
                    <RenderStatus
                        paymentStatus={row.sStatus["sPaymentStatus"]}
                        status={row.sStatus["sStatus"]}
                    />
                </TableCell>

                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box>
                            <Table size="small" aria-label="purchases">
                                <TableHead className={styles.tableHead}>
                                    <TableRow>
                                        <TableCell>
                                            <Typography
                                                variant="h6"
                                                gutterBottom
                                                component="div"
                                                className={
                                                    styles.descriptionText
                                                }
                                            >
                                                Información
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>

                            <Table size="small" aria-label="purchases">
                                <TableHead className={styles.tableHead}>
                                    <TableRow>
                                        <TableCell className={styles.cellTitle}>
                                            Fecha
                                        </TableCell>
                                        <TableCell className={styles.cellTitle}>
                                            Documentos descargados
                                        </TableCell>
                                        <TableCell className={styles.cellTitle}>
                                            Participantes
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.information.map((informationRow) => (
                                        <TableRow key={row["sContractID"]}>

                                            <TableCell className={styles.rowText}>
                                                {row.information[0].dContractDT}
                                            </TableCell>

                                            <TableCell
                                                className={styles.rowText}
                                            >
                                                {informationRow.withDocument ===
                                                    1
                                                    ? "SI"
                                                    : "NO"}
                                            </TableCell>

                                            <TableCell
                                                className={styles.rowText}
                                            >
                                                {informationRow.iSignedCount}
                                                <FaUserAlt className="icon--xxs icon--blue-light" />
                                            </TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
};

Rows.propTypes = {
    row: PropTypes.shape({
        sContractID: PropTypes.string.isRequired,
        action: PropTypes.string,
        autoId: PropTypes.string.isRequired,
        tramit: PropTypes.object.isRequired,
        sStatus: PropTypes.object.isRequired,
        information: PropTypes.arrayOf(
            PropTypes.shape({
                dContractDT: PropTypes.string.isRequired,
                withDocument: PropTypes.number.isRequired,
                iSignedCount: PropTypes.number.isRequired,
            })
        ).isRequired,
    }).isRequired,
};

export default Rows;