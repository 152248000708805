import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import FilePreview from "react-file-preview-latest";
import { MdModeEdit, MdOutlineCancelPresentation } from "react-icons/md";
import * as PdfjsLib from "pdfjs-dist";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";

import { ReactComponent as FolderIcon } from "../../icons/folder.svg";
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/icon-close-gray-light.svg';

import { ExpressProcessStyles } from "../../index.css";
import ScreenSizeHook from "../../../../../hooks/ScreenSizeHook";
import { mergeDocumentsOptions2 } from "../../../../paperworkProcess/documents/services";
import { asyncBlobToBase64 } from "../../../../../utils/enums";
import { saveTramitExpressInfo } from "../../../../../store/formExpressTramit";
import { IconButton } from "@mui/material";
import { theme } from "../../../../../theme/theme";

const TextIconfolder = styled(Typography)((props) => ({
    color: props.theme.palette.gray.dark,
    fontFamily: ['"PoppinsBold"'].join(","),
}));

const style = {
    p: 4,
};

const MergeCustomButtom = styled(Button)((props) => ({
    width: '8rem',
    borderRadius: "7px",
    fontStyle: "normal",
    boxShadow: "none",
    fontWeight: "bolder",
    textTransform: "none",
    fontSize: "0.9rem",
    marginTop: "11px",
    border: `1px solid ${props.theme.palette.primary.light}`,
    order: 2,
    "&:hover": {
        border: `1px solid ${props.theme.palette.primary.light}`,
    },
    fontFamily: ['"PoppinsBold"'].join(","),
    [theme.breakpoints.up('lg')]: {
        width: '12rem',
        order: 1,
        marginRight: '1.2rem'
    }
}));

const CancelMergeCustomButtom = styled(Button)((props) => ({
    width: '8rem',
    borderRadius: "7px",
    fontStyle: "normal",
    boxShadow: "none",
    fontWeight: "bolder",
    textTransform: "none",
    color: props.theme.palette.secondary.main,
    fontSize: "0.9rem",
    marginTop: "11px",
    border: `1px solid ${props.theme.palette.secondary.light}`,
    order: 1,
    "&:hover": {
        border: `1px solid ${props.theme.palette.secondary.light}`,
    },
    fontFamily: ['"PoppinsBold"'].join(","),
    [theme.breakpoints.up('lg')]: {
        width: '12rem',
        order: 2,
    }
}));

const UnirDocsModal = ({
    openModalDocs,
    textLoadingModaldocs,
    loadingModaldocs,
}) => {
    const screenSizeHook = ScreenSizeHook();
    const dispatch = useDispatch();
    const styles = ExpressProcessStyles({ screenSizeHook });
    const dragItem = useRef(null);
    const dragOverItem = useRef(null);
    const inputMergeRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [arrayMerge, setArrayMerge] = useState([]);
    const [errorsFiles, setErrorsFiles] = useState([]);

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        // setArrayMerge([]);
        setOpen(false);
    };

    const mergePaddingButtoms = {
        padding: screenSizeHook === "xs" ? "10px 10px" : "11px 32px",
    };

    const addData = (array) => {
        return new Promise((resolve) => {

            const formData = new FormData();

            array.forEach((element) => {
                formData.append('files', element["file"]);
            });

            resolve(formData);

        });
    };

    const mergeDocs = async () => {
        try {
            loadingModaldocs(true);

            textLoadingModaldocs(
                "Este proceso puede durar un par de minutos por favor espere..."
            );

            const formData = await addData(arrayMerge);

            const response = await mergeDocumentsOptions2(formData);

            const fileBase64 = await asyncBlobToBase64(response);

            dispatch(
                saveTramitExpressInfo({
                    indexe: "document",
                    value: fileBase64,
                })
            );

            dispatch(
                saveTramitExpressInfo({
                    indexe: "fileName",
                    value: "documento-unido",
                })
            );

            loadingModaldocs(false);
            handleClose();
            setArrayMerge([]);
        } catch (error) {
            loadingModaldocs(false);
            handleClose();
            console.log(error);
        }
    };

    const handleFileMergeChange = async (event) => {
        const arrayData = [...arrayMerge];
        const errors = [];
        const allowedTypes = [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'image/png',
            'image/jpg',
            'image/jpeg'
        ];

        try {
            for (let index = 0; index < event.target.files.length; index++) {
                const element = event.target.files[index];

                if (element.type === 'application/pdf') {
                    const arrayBuffer = await element.arrayBuffer();
                    await PdfjsLib.getDocument({ data: arrayBuffer }).promise;
                }
                console.log({ element })

                if (!allowedTypes.includes(element.type || element.type === "")) {
                    console.log('Uno de los archivos que has intentado unir no es permitido, por favor, incluye archivos .pdf, .doc, .docx, .jpg, .jpeg o .png.')
                    errors.push('Uno de los archivos que has intentado unir no es permitido, por favor, incluye archivos .pdf, .doc, .docx, .jpg, .jpeg o .png.');
                };
                // const restDocPdfBase64 = await asyncFileToBase64(element);

                // const docPdfBase64 = restDocPdfBase64.split("base64,");
                console.log({ errors })
                if (errors.length > 0) {
                    setErrorsFiles(errors);
                } else {
                    arrayData.push({
                        file: element,
                        name: element.name,
                        // docPdfBase64: docPdfBase64[1],
                    });
                };
            };

            event.target.value = null;

            setArrayMerge(arrayData);
            setErrorsFiles([]);
        } catch (error) {
            errors.push('Uno de los archivos que has intentado subir está dañado. Por favor, intenta cargar otro.');
            setErrorsFiles(errors);
        };
    };

    const handleMergeClick = () => {
        inputMergeRef.current.click();
    };

    const removeFileFromArray = (name) => {
        const arrayData = [...arrayMerge].filter(
            (item) => item["name"] !== name
        );
        setArrayMerge(arrayData);
    };

    const handleSort = () => {
        //duplicate items
        let _arrayMerge = [...arrayMerge];

        //remove and save the dragged item content
        const draggedItemContent = _arrayMerge.splice(dragItem.current, 1)[0];

        //switch the position
        _arrayMerge.splice(dragOverItem.current, 0, draggedItemContent);

        //reset the position ref
        dragItem.current = null;
        dragOverItem.current = null;

        //update the actual array
        setArrayMerge(_arrayMerge);
    };

    useEffect(() => {
        if (openModalDocs > 0) handleOpen();
    }, [openModalDocs]);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className={styles.modalDocsContent}>

                    <IconButton
                        className={styles.modalDocsContent_closeIcon}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>

                    <div className="body-modal-content">
                        <div className={`${styles.fileButomsContainer} form__container`}>
                            <Box className={styles.modalMergeFiles__title__box}>
                                <Typography className={styles.modalMergeFiles__title}>
                                    Adjuntar documentos
                                </Typography>
                                <Typography className={styles.modalMergeFiles__subTitle}>
                                    Documentos solicitados a firmar
                                </Typography>
                            </Box>

                            <Box className={styles.modalMergeFiles__textBody__box}>

                                <Typography className={styles.modalMergeFiles__textBody__tertiaryText}>
                                    Adjunta los documentos necesarios para continuar con el  trámite.
                                </Typography>

                                <Typography className={styles.modalMergeFiles__textBody__primaryText}>
                                    Recuerda adjuntar como mínimo: contrato a firmar y cédulas de los participantes o firmantes. Si corresponde,
                                    el certificado de representante legal (e-RUT).
                                </Typography>

                                <Typography className={styles.modalMergeFiles__textBody__secondaryText}>
                                    Todos los archivos deben estar unidos para ser parte de un (1) solo archivo PDF.
                                </Typography>
                            </Box>

                            <div
                                style={{
                                    margin: '1.8rem 0 0 0',
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <CancelMergeCustomButtom
                                    style={{
                                        marginRight:
                                            screenSizeHook !== "xs"
                                                ? "20px"
                                                : "16px",
                                    }}
                                    sx={mergePaddingButtoms}
                                    onClick={() => {
                                        handleClose();
                                        setArrayMerge([]);
                                    }}
                                    variant="outlined"
                                    color="primary"
                                    startIcon={
                                        <MdOutlineCancelPresentation
                                            style={{
                                                borderRadius: "5px",
                                            }}
                                            className="icon--white icon--md icon-back-ground-color-red"
                                        />
                                    }
                                >
                                    Cancelar
                                </CancelMergeCustomButtom>

                                <MergeCustomButtom
                                    fullWidth={
                                        screenSizeHook === "xs"
                                            ? true
                                            : false
                                    }
                                    sx={mergePaddingButtoms}
                                    onClick={() => mergeDocs()}
                                    variant="outlined"
                                    color="primary"
                                    startIcon={
                                        <MdModeEdit
                                            style={{ borderRadius: "5px" }}
                                            className="icon--white icon--md icon-back-ground-color-blue"
                                        />
                                    }
                                    disabled={arrayMerge.length < 2}
                                >
                                    Unir
                                </MergeCustomButtom>
                            </div>

                            {errorsFiles.length > 0 && errorsFiles.map(ele => (
                                <Typography>
                                    {ele}
                                </Typography>
                            ))}

                            {arrayMerge.map((item, index) => {
                                return (
                                    <div
                                        className={styles.fileMergePreview}
                                        key={item["name"] + index}
                                        draggable
                                        onDragStart={(e) => (dragItem.current = index)}
                                        onDragEnter={(e) => (dragOverItem.current = index)}
                                        onDragEnd={handleSort}
                                        onDragOver={(e) => e.preventDefault()}
                                    >
                                        <div className={styles.conatinerCloseButtomPreview}>
                                            <ControlCameraIcon className="icon--blue-light icon-move" />

                                            <DisabledByDefaultIcon
                                                className="icon--red icon-pointer"
                                                onClick={() =>
                                                    removeFileFromArray(
                                                        item["name"]
                                                    )
                                                }
                                            />
                                        </div>

                                        <Typography
                                            variant="subtitle1"
                                            gutterBottom
                                            align="center"
                                            color={"gray.dark"}
                                        >
                                            {item["name"].length < 19
                                                ? item["name"]
                                                : item["name"].substring(
                                                    0,
                                                    15
                                                ) + "..."}
                                        </Typography>

                                        <FilePreview
                                            type={"file"}
                                            file={item["file"]}
                                            onError={() => console.log("error")}
                                        />

                                    </div>
                                );
                            })}

                            <div
                                className={styles.folderIconContainer}
                                onClick={handleMergeClick}
                            >
                                <input
                                    hidden
                                    accept="image/png, image/jpeg, image/jpg, application/pdf, .docx, .docm, .doc, .pptx, .pptm, .potx, .ppsx"
                                    style={{ display: "none" }}
                                    ref={inputMergeRef}
                                    type="file"
                                    multiple
                                    onChange={handleFileMergeChange}
                                />

                                <FolderIcon />

                                <TextIconfolder
                                    variant="subtitle1"
                                    gutterBottom
                                >
                                    Añadir archivo
                                </TextIconfolder>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default UnirDocsModal;
