import { Fragment, createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Webcam from "react-webcam";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import { useScreenshot } from "use-react-screenshot";

import { IoCameraOutline } from "react-icons/io5";

import { ReactComponent as DniIcon } from "../icons/dni-icon.svg";

import { useNavigate } from "react-router-dom";
import { to, verifiVariable } from "../../../../utils/enums";
import { saveTramitANFInfo } from "../../../../store/formANFTramit";
import { SignAuthorizationStyles } from "../index.css";
import ModalContractUploadError from "../../../modalContractUploadError";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    color: theme.palette.text.secondary,
    boxShadow: "unset",
}));

const NextButton = styled(Button)((props) => ({
    width: "60%",
    fontStyle: "normal",
    boxShadow: "none",
    fontWeight: "bolder",
    textTransform: "none",
    fontSize: 16,
    padding: "11px 28px",
    border: "1px solid",
    borderRadius: "7px",
    lineHeight: 1.5,
    backgroundColor: props.theme.palette.primary.light,
    borderColor: props.theme.palette.primary.light,
    fontFamily: ['"PoppinsBold"'].join(","),
    "&:hover": {
        backgroundColor: props.theme.palette.primary.xlight,
        borderColor: props.theme.palette.primary.xlight,
        boxShadow: "none",
    },
    "&:active": {
        boxShadow: "none",
        backgroundColor: "#0062cc",
        borderColor: "#005cbf",
    },
    "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
}));

const CapturePhotoButtom = styled(Button)((props) => ({
    border: `1px solid ${props.theme.palette.primary.light}`,
    color: props.theme.palette.primary.light,
    borderRadius: "7px",
    fontStyle: "normal",
    boxShadow: "none",
    fontWeight: "bolder",
    textTransform: "none",
    fontSize: "0.9rem",
    padding: "11px 28px",
    width: "60%",
    fontFamily: ['"PoppinsBold"'].join(","),
}));

const DniCature = () => {
    const styles = SignAuthorizationStyles();
    const [image, takeScreenshot] = useScreenshot();
    const {
        formANFTramit: { dniInfo },
    } = useSelector((state) => state);
    const webcamRef = createRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openModalErrors, setOpenModalErrors] = useState(false);
    const [cameraGranted, setCameraGranted] = useState(false);
    const [errorsUpload, setErrorsUpload] = useState([]);

    const removeFileFromArray = (index) => {
        const arrayData = [...dniInfo];

        arrayData.splice(index, 1);

        dispatch(
            saveTramitANFInfo({
                indexe: "dniInfo",
                value: arrayData,
            })
        );
    };

    const capture = () => takeScreenshot(webcamRef.current);

    useEffect(() => {

        (async () => {
            try {

                const msnError = [];

                // 1. Verificar si hay cámara o micrófonos disponible
                const devices = await navigator.mediaDevices.enumerateDevices();
                const videoDevices = devices.filter(device => device.kind === 'videoinput');
                const audioDevices = devices.filter(device => device.kind === 'audioinput');

                // Si no hay cámaras disponibles
                if (videoDevices.length === 0) msnError.push("Acceso cámara.", "No se encontró ninguna cámara conectada.");

                // Si no hay micrófonos disponibles
                if (audioDevices.length === 0) msnError.push("Acceso micrófono.", "No se encontró ningún micrófono conectado.")

                if (videoDevices.length === 0 || audioDevices.length === 0) {
                    // De no haber alguno de los dispositivos mostrar mensaje
                    setErrorsUpload(msnError);
                    setOpenModalErrors(true);
                    return;
                }

                // 2. Si existen dispositivos, solicitar acceso
                try {
                    await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
                    setCameraGranted(true);
                } catch (error) {
                    // Si el acceso es denegado o ocurre un error al intentar obtenerlo
                    console.error("Acceso denegado o error:", error);
                    setCameraGranted(false);
                    setErrorsUpload(["Acceso dispositivos.", "Debe dar permisos de acceso a la cámara y/ó micrófono."]);
                    setOpenModalErrors(true);
                }

            } catch (error) {
                // Manejar errores al verificar dispositivos disponibles
                console.error("Error al enumerar los dispositivos multimedia:", error);
                setErrorsUpload(["Acceso dispositivos.", "Hubo un error al verificar los dispositivos disponibles."]);
                setOpenModalErrors(true);
                setCameraGranted(false);
            }
        })();

    }, []);

    useEffect(() => {
        if (
            !verifiVariable(image) &&
            !verifiVariable(dniInfo) &&
            dniInfo.length < 2
        ) {
            const arrayData = [...dniInfo];

            arrayData.push(image);

            dispatch(
                saveTramitANFInfo({
                    indexe: "dniInfo",
                    value: arrayData,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image]);

    return (
        <Box>
            <Grid container>
                <Grid item xs={12}>

                    <ModalContractUploadError
                        openModalErrors={openModalErrors}
                        setOpenModalErrors={setOpenModalErrors}
                        data={errorsUpload}
                        modalIcon={'warnIcon'}
                    />

                    <Item className={styles.itemContent}>
                        <Typography
                            className={`${styles.mainTitle} ${styles.mainTitleDni}`}
                            variant="h6"
                            gutterBottom
                        >
                            Por favor, verifique su identidad
                        </Typography>

                        <Divider variant="fullWidth" />

                        <Typography
                            className={`${styles.errorInfoText} ${styles.subTitleDni1}`}
                            variant="subtitle1"
                            gutterBottom
                        >
                            Por favor, realice una{" "}
                            <span> (1) fotografía del anverso y reverso </span> de su
                            identificación.
                        </Typography>

                        {!verifiVariable(dniInfo) && dniInfo.length === 2 && (
                            <NextButton
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    dispatch(
                                        saveTramitANFInfo({
                                            indexe: "status",
                                            value: null,
                                        })
                                    );

                                    navigate(to.SIGN_VERIFY);
                                }}
                            >
                                Siguiente
                            </NextButton>
                        )}

                        {!verifiVariable(dniInfo) && dniInfo.length < 2 && (
                            <Fragment>
                                {cameraGranted === true && (<CapturePhotoButtom
                                    variant="outlined"
                                    onClick={capture}
                                    // onClick={handleCapture}
                                    startIcon={
                                        <IoCameraOutline className="icon--blue icon--md" />
                                    }
                                >
                                    Tomar foto
                                </CapturePhotoButtom>)}

                                {cameraGranted === true && (<Box
                                    className={styles.captureContainer}
                                    ref={webcamRef}
                                >
                                    <Webcam
                                        audio={false}
                                        height={310}
                                        screenshotFormat="image/png"
                                        width={310}
                                        videoConstraints={{
                                            width: 310,
                                            height: 310,
                                            facingMode: "environment",
                                        }}
                                    />

                                    <DniIcon />
                                </Box>)}
                            </Fragment>
                        )}

                        <br />

                        {!verifiVariable(dniInfo) &&
                            [...dniInfo].map((item, index) => {
                                return (
                                    <div
                                        className={
                                            styles.fileMergePreviewDniCapture
                                        }
                                        key={index}
                                    >
                                        <div
                                            className={
                                                styles.conatinerCloseButtomPreview
                                            }
                                        >
                                            <Typography
                                                className={`${styles.mainTitle} ${styles.mainTitleDni}`}
                                                variant="h6"
                                                gutterBottom
                                            >
                                                {index === 0
                                                    ? "Capture cédula frontal"
                                                    : "Capture cédula trasera"}
                                            </Typography>

                                            <DisabledByDefaultIcon
                                                className="icon--red icon-pointer"
                                                onClick={() =>
                                                    removeFileFromArray(index)
                                                }
                                            />
                                        </div>

                                        <img alt="" src={item} />
                                    </div>
                                );
                            })}
                    </Item>
                </Grid>
            </Grid>
        </Box>
    );
};

export default DniCature;
